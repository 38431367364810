
* {
    -ms-overflow-style: none;
    margin: 0px;
    padding: 0px;
}
::-webkit-scrollbar {
    display: none;
}
.homeWrapper {
    color: white;
}
