#Hamburger_Menu a {
        display: block;
        padding: 10px 40px;
        text-decoration: none;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
}
.navBar {
    position: relative;
}
.navBar button {
    cursor: pointer;
}
.menuNav {
    list-style: none;
    position: fixed;
    top: 12%;
    right: 0;
    bottom: 0;
    height: 88vh;
    overflow: hidden;
    max-width: 290px;
    z-index: 9;
    transition: .5s;
    border-top-left-radius: 2.5em;
    background: #0B132B;
    display:flex;
    flex-direction: column;
    border: 2.5px solid rgb(158, 158, 158);
    border-right: none;
}
.menuNav {
    width: 0%;
    visibility: hidden;
}
.menuNav li a {
    visibility: hidden;
}
.menuNav.showMenu {
    visibility: visible;
    width: 80%;
    display: flex;
    justify-content: center;
}
.menuNav.showMenu li a {
    visibility: visible;
    margin-bottom: 25%;
    height: 20%;
    border-radius: .5em;
}
.menuNav li:first-child {
    margin-top: 25%;
}
.navBar button {
    background: transparent;
    border: none;
}
#nav li a.active {
    box-shadow: 0 0 5px #5BC0BE;
}
#nav li a {
    color: white;
    border: 1px solid transparent;
}
nav li a:hover:not(.active) {
    background-color: #5BC0BE;
}
@media (max-width: 900px){
    .menuNav.showMenu li a {
        height: 50%;
    }
}