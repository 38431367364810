@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

.aboutSectionRight {
    width: 25%;
    margin-right: 10%;
    padding-bottom: 10%;;
}
.skills img {
    border-radius: 50%;
    height: 55px;
    box-shadow: 0 0 2px whitesmoke;
    margin: 1%;
}
#headshot {
    border-radius: 50%;
    width:55%;
    height:55%;
    border: 5px solid #5BC0BE;
}
.aboutMePageWrap {
    background: linear-gradient(#1C2541, #3A506B);
    margin: 0;
}
.AboutMeBody {
    color: white;
    padding-top: 10%;
}
.skills h4 {
    font-family: 'Righteous', cursive;
}
.trifold {
    display: flex;
    justify-content: space-around;
    align-items: top;
}
.trifold div {
    width: 30%;
}
.trifold div h3 {
    font-family: 'Abril Fatface', cursive;
}
.skills p {
    text-align: center;
    font-style: italic;
}
.mainSection {
    width: 80%;
}
.innerDiv {
    display: flex;
}

@media (max-width: 900px){
    .aboutSectionRight {
        width: 80%; 
        margin-left: 10%;
    }
    div.skills li {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .skills li div h4 {
        padding-left: 0;
    }
    .trifold {
        flex-direction: column;
    }
    .trifold div {
        width: 80%;
        margin-left: 10%;
        margin-bottom: 2%;
    }
}
