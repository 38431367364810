@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');

.sectionWrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: center;
}
#noClicky {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
div.socialLinks {
    display: flex;
    width: 50%;
    margin-left: 25%;
    justify-content: space-evenly;
}
button.moreBtn {
    background-color: #5BC0BE;
    color: #1C2541;
    height: 3em;
    padding: .5em;
    border-radius: 25em;
    border: 2px solid white;
    font-weight: bold;
}
.moreBtn:hover {
    cursor: pointer;
    box-shadow: 0 0 5px whitesmoke;
    color: white;
}
.mainDiv {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
img#githubSocial {
    height: 75%;
    width: 75%;
}
img#linkedinSocial {
    height: 75%;
    width: 75%;
}
.SectionIconLink {
    height: 100%;
    overflow: hidden;
}
.SectionIconLink a {
    text-decoration: none;
    color: white;
}
.SectionIconLink a:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
#emailMe {
    color: white;
    text-decoration: none;
}
#phoneMe {
    color: white;
    text-decoration: none;
}
#phoneMe:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
#emailMe:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
* h1 {
    font-family: 'Lato', sans-serif;
    font-size: 3.5em;
}
#ProjectPgNum {
    bottom:55%;
}
#aboutMePgNum {
    bottom:30%;
}
#getInTouchPgNum {
    bottom:5%;
}
#ProjectsSectionRight img{
    width:70%;
}
.sectionRight {
    width: 50%;
}
.numbers {
    font-family: 'Ultra', serif;
    color: #5BC0BE;
    position: absolute;
    z-index: 1000;
    right:10%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.ProjectNumbers {
    font-family: 'Ultra', serif;
    color: #5BC0BE;
}
.rightTitle {
    position: absolute;
    z-index: 1000;
    right:12%;
}
#section1RightTitle {
    bottom: 82%;
}
#section1RightTitle2 {
    display:none;
}
#Section3HeadShot {
    width: 35%;
    border-radius: 50%;
    border: 5px solid #5BC0BE;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.swiper img:hover {
    cursor: pointer;
}
@media (max-width: 768px){
    .sectionWrap {
        display: flex;
        flex-direction: column;
        align-content: center;
    }
    div.mainDiv {
        width: 80%;
        margin-left: 0%;
    }
    .sectionRight {
        width:95%;
        margin-left: 2.5%;
    }
    #ProjectsSectionRight img {
        width: 100%;
    }
    div.socialLinks {
        margin-right: none;
        align-items: top;
    }
    #ProjectPgNum {
        bottom: 50%;
    }
    #aboutMePgNum {
        bottom:25%;
    }
    #getInTouchPgNum {
        bottom:0;
    }
    .lineBreak {
        display: none;
    }
    .sectionRight img {
        width:50%;
    }
    #section1RightTitle {
        display:none;
    }
    #section1RightTitle2 {
        bottom: 77%;
        right:6%;
        display: block;
    }
    div.SectionIconLink {
        height: 80%;
        overflow:visible;
    }
}
