
.projectWrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    padding-top: 10%;
    color: white;
}
.projectLeft {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.projectRight {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 10%;
}
.innerDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:90%;
}
.projectLinks {
    display: flex;
    justify-content: space-around;
}
.projectLinks a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.projectLinks a:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
.projectImgDiv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding:1%;
    width:80%;
    /* margin-left: 10%; */
}
.projectImgDiv img {
    width: 100%;
    border-radius: 2.5%;
    border: 2.5px solid rgb(158, 158, 158);
    padding:1%;
}
.projectImgDiv a img:hover {
    box-shadow: 0 0 5px whitesmoke;
}

.projectPageWrap {
    background: linear-gradient(#1C2541, #3A506B);
}
@media (max-width: 768px){
    .ProjectFullPage {
        display: block;
    }
    #CoverFlowElement {
        display: none;
    }
    div.projectRight {
        margin-left: 10%;
    }
}
@media (min-width: 768px) {
    .ProjectFullPage {
        display: none;
    }
}
