.headWrap {
    margin: 0;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
    height: 10%;
    display:flex;
    justify-content: space-around;
}
.headRight {
    right: 2%;
    padding-top:1%;
    position: fixed;
    display: flex;
    justify-content: space-evenly;
    z-index: 1000;
}
.headRight #navbarOnHead {
    padding: 2.5%;
}
.headRight {
    width: 25%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.headRight a {
    display: flex;
    justify-content: space-around;
    list-style: none;
    overflow: hidden;
    height: 75%;
    padding:2.5%;
}
.headRight ul a img{
    display: inline-block;
    height: 100%;
}
.headLeft {
    padding: 1.5%;
    left: 2%;
    position: fixed;
    z-index: 1000;
}
.headWrap a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}
.headWrap a:hover {
    text-shadow: 0px 0px 10px whitesmoke;
}

.github {
    border-radius: 50%;
}
.github:hover {
    box-shadow: 0 0 5px whitesmoke;
}
.linkedin {
    border-radius: 15%;
}
.linkedin:hover {
    box-shadow: 0 0 5px whitesmoke;
}
.HomeIcon {
    width: 12%;
}
.HomeIcon img {
    width:100%;
}
@media (max-width: 600px){
    .headWrap {
        display: flex;
        flex-direction: column;
    }
    .headRight {
        width:95%;
        padding-top: 25%;
    }
    .headLeft {
        display:none;
    }
}