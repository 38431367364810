@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

.sectionWrap {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    align-items: center;
    margin: 0;
}
.socialLinks {
    display: flex;
    width: 50%;
    margin-left: 25%;
    justify-content: space-evenly;
}
.moreBtn {
    background-color: #5BC0BE;
    color: #1C2541;
    height: 2.5em;
    border-radius: .5em;
    border: 2px solid white;
    font-weight: bold;
}
.moreBtn:hover {
    cursor: pointer;
    box-shadow: 0 0 5px whitesmoke;
    color: white;
}
#githubSocial {
    border-radius: 50%;
    height: 25%;
    width: 25%;
}
#linkedinSocial {
    border-radius: 15%;
    height: 25%;
    width: 25%;
}
.SectionIconLink {
    height: 25%;
    overflow: hidden;
}
.SectionIconLink a {
    text-decoration: none;
    color: white;
}
.SectionIconLink a:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
#emailMe {
    color: white;
    text-decoration: none;
}
#emailMe:hover {
    text-shadow: 0px 0px 5px whitesmoke;
}
* h1 {
    font-family: 'Lato', sans-serif;
    font-size: 3.5em;
}
.skills p {
    color: greenyellow;
    margin-bottom: 1%;
}
.sectionLeft {
    width:50%;
}
#topDiv {
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    text-align: left;
}
#bottomDiv {
    list-style: none;
}
:root {
    --swiper-theme-color: #5BC0BE;
}
.swiper {
    color: #5BC0BE;
}
@media (max-width: 768px){
    .sectionWrap {
        padding-top:10%;
    }
    #hideOnMobile {
        display: none;
    }
    .skills h4 {
        margin-bottom: 4%;
    }
    .skills p {
        margin-bottom: 2%;
    }
}